<template>
<!-- 添加设备 -->
    <div class="add-cert">
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="设备类型" required prop="name">
                 <el-select v-model="form.name" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="设备编号" required prop="num">
                <el-input v-model="form.num" ></el-input>
            </el-form-item>
            <el-form-item label="设备区域">
                <el-input v-model="form.area" ></el-input>
            </el-form-item>
            <el-form-item label="所属客户" required prop="client">
                <el-autocomplete
                        class="inline-input"
                        v-model="form.client"
                        :fetch-suggestions="searchClient"
                        placeholder="搜索客户"
                        :trigger-on-focus="false"
                        @select="selectClient"
                        ></el-autocomplete>
            </el-form-item>
            <el-form-item >
                <el-button type="primary" @click="onSubmit">
                    提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        return{
            form:{
                name:'',
                num:'',
                client:'',
                area:'',
                loading:true
            },
            options:[],
            rules:{
                name: [
                    { required: true, message: '请选择设备类型', trigger: 'blur' },
                    { min: 1, max: 10, message: '设备类型字符过长', trigger: 'blur' }
                ],num: [
                    { required: true, message: '请输入设备编号', trigger: 'blur' }
                ],client: [
                    { required: true, message: '请选择客户名称', trigger: 'blur' }
                ]
            }
        }
    },
    created(){
        this.getDeviceType()
    },
    methods:{
        //设备类型
        getDeviceType(){
            //devicetype
            let params = {
                        method:'devicetype'
                    }
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false
                        let options = []
                        res.data.forEach(item=>{
                            options.push({
                                value:item.t_name,
                                label:item.t_name
                            })
                        })
                        this.options = options
                    }).catch(err=>{
                        //err
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
        },
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if(valid){
                    let params = this.form 
                    params.method = "addDevice"
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false 
                        if(res){
                            this.$message({
                            message: res.msg||"添加设备成功！",
                            type: 'success'
                        });
                        this.form.name=''
                        this.form.num=''
                        this.form.area=''
                        }
                        this.$emit("close")
                    }).catch(err=>{
                        //err
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
                }
            })
        },
        //选择客户
        selectClient(e){
            console.log(e)
        },
        //弹出搜索客户
        searchClient(queryString, cb){
            let params = {
                        method:'getClientList',
                        key:this.form.keys,
                        page:1,
                        pageSize:5
                    }
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false
                        console.log(res)
                        if(res.data.list){
                            let clients = res.data.list;
                            console.log(clients)
                            let results = queryString ? 
                            clients.filter(this.createFilter(queryString)) : clients;
                            // 调用 callback 返回建议列表的数据
                            console.log(results)
                            cb(results);
                        }
                    }).catch(err=>{
                        //err
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
        },
        createFilter(queryString) {
            return (clients) => {
                clients.value = clients.shortname
            return (clients.shortname.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
    }
}
</script>