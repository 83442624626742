<template>
    <div class="dev-box">
         <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>设备管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button @click="ifAddDevice=true" type="primary" size="small" icon="el-icon-plus" round>添加</el-button>
                </el-form-item>
                <el-form-item v-if="addRule">
                    <el-button @click="showImport=true" 
                    type="warning" size="small" icon="el-icon-upload2" round>导入</el-button>
                </el-form-item>
                <el-form-item v-if="exportRule">
                    <el-button round plain @click="download" type="success" size="small" icon="el-icon-download">导出</el-button>
                </el-form-item>
                <el-form-item v-if="showDel&&modifyRule">
                    <el-button @click="deleteList" 
                    type="danger" size="small" icon="el-icon-delete-solid" round>删除</el-button>
                </el-form-item>
                <el-form-item label="客户">
                        <el-autocomplete
                        class="inline-input"
                        v-model="form.client"
                        :fetch-suggestions="searchClient"
                        placeholder="搜索客户"
                        :trigger-on-focus="false"
                        @select="selectClient"
                        ></el-autocomplete>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="reset" size="small">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- list-client -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="list"
             @selection-change="handleSelectionChange"
            style="width: 100%">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                    prop="d_num"
                    width="100"
                    label="设备编号">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="设备名称">
                </el-table-column>
                <el-table-column
                    prop="client"
                    label="所属客户">
                    <template slot-scope="scope">
                        <div class="name" style="cursor:pointer" @click="searchKeys(scope.row.client)">
                            {{scope.row.client}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="area"
                    width="120"
                    label="区域">
                </el-table-column>
                <el-table-column
                    width="100"
                    fixed="right"
                    v-if="modifyRule"
                    label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row)"></i>
                        <i class="edit el-icon-edit" @click="handleUpadte(scope.row)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- list-client-end -->
        <!-- page分页 -->
        <div class="pageNav" v-if="list.length>=1">
            <el-pagination
                hide-on-single-page
                v-model="form.page"
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
            </el-pagination>
            <el-dropdown>
                <span class="el-dropdown-link">
                    每页显示 {{pageSize}} 条<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="choisePageSize(item)" v-for="item in pageArr" :key="item">
                        {{item}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- page-end -->
        <!-- import -->
        <el-dialog title="导入设备" :visible.sync="showImport">
            <ImportDevice @close="showImport=false" @download="downloadMoudle"/>
        </el-dialog>
        <!-- add -->
        <el-dialog title="添加设备" :visible.sync="ifAddDevice">
            <AddDevice @close="ifAddDevice=false" @download="downloadMoudle"/>
        </el-dialog>
    </div>
</template>
<script>
import ImportDevice from '@/components/importDevice'
import AddDevice from '@/components/AddDevice'
import {exportExcel} from '@/utils/excel.js'
import {checkRules} from '@/utils/util.js'
export default {
    components:{
        ImportDevice,
        AddDevice
    },
    data(){
        return{
            list:[],
            totalPage:1,
            addRule:checkRules(6),//添加客户权限
            exportRule:checkRules(8),//导出客户权限
            modifyRule:checkRules(7),//修改客户权限
            form:{
                method:'getDeviceList',
                page:1,
                keys:'',
                client:''
            },
            loading:false,
            pageArr:[6,10,15,20],
            pageSize:10,
            selectList:[],//选择的数据
            showDel:false,//显示删除按钮
            showImport:false,//导入
            ifAddDevice:false,//添加
        }
    },
    created(){
        this.getDevice()
    },
    methods:{
        //下载模板
        downloadMoudle(){
            let columnTitle=['客户id(非必须)','客户','设备名称','设备编号','设备区域']
                let columnData = []
                exportExcel(columnTitle, columnData, "客户设备导入明细表");
        },
        //删除多个
        deleteList(){
            this.$confirm('此操作将永久删除选择的数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.confirmDel()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        //确定删除
        confirmDel(){
            let ids='',l=this.selectList.length 
            if(l<=0){
                return
            }
            this.selectList.forEach((item,index)=>{
                if(index!=l-1){
                    ids += item.did+","
                }else{
                    ids += item.did
                }
            })
            let params = {
                method:"delDeviceList",
                ids
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                console.log(res)
                this.loading = false 
                this.$message({
                        message: res.msg||'操作成功！',
                        type: 'success'
                    });
                this.search()
                this.selectList=[]
            if(res.data){
                this.totalPage = res.data.totalPage 
                this.list = res.data.list
            }
            //console.log(list)
            }).catch(err=>{
            //err
            console.log(err.data)
                this.loading = false
                this.$message({
                        message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
            }) 
        },
        searchKeys(e){
            this.form.client = e
            this.search()
        },
        //表单选择
        handleSelectionChange(e){
            console.log(e)
            this.selectList=e
            e.length<=0?this.showDel=false:this.showDel=true
        },
        search(){
            this.form.page = 1;
            this.getDevice()
        },
        reset(){
            this.page = 1;
            this.form.keys = ""
            this.form.client = ""
        },
        selectClient(e){
            console.log(e)
        },
        add(){

        },//添加
        //更新设备
        handleUpadte(row){
            console.log(row)
        },
        //删除设备
        handleDelete(data){
            console.log(data)
            this.selectList.push(data)
            this.deleteList()
        },
        getDevice(){
            let params = this.form 
            params.pageSize = this.pageSize
            console.log(this.form.page,'---')
            console.log(params)
            this.$ajax.post(this.API.api,params).then(res=>{
            //todo
            this.loading = false 
            if(res.data){
                this.totalPage = res.data.totalPage 
                this.list = res.data.list
            }
            //console.log(list)
            }).catch(err=>{
            //err
            console.log(err.data)
            this.loading = false
            this.$message({
                        message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
            }) 
        },//弹出搜索客户
        searchClient(queryString, cb){
            let params = {
                        method:'getClientList',
                        key:this.form.keys,
                        page:1,
                        pageSize:5
                    }
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false
                        console.log(res)
                        if(res.data.list){
                            let clients = res.data.list;
                            console.log(clients)
                            let results = queryString ? 
                            clients.filter(this.createFilter(queryString)) : clients;
                            // 调用 callback 返回建议列表的数据
                            console.log(results)
                            cb(results);
                        }
                    }).catch(err=>{
                        //err
                        this.$message({
                            message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                            type: 'warning'
                        });
                    })
        },
        createFilter(queryString) {
            return (clients) => {
                clients.value = clients.shortname
            return (clients.shortname.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        //导出
        download(){
            let params = {
                method:'getDeviceList',
                page:1,
                pageSize:1000
            }
            params.pageSize = 10000;
            this.$ajax.post(this.API.api,params).then(res=>{
            //todo
            this.loading = false 
            if(res.data){
                let list = res.data.list
                console.log(list)
                let columnTitle=['客户id','客户','设备名称','设备编号','设备区域']
                let columnData = []
                res.data.list.forEach(item=>{
                    columnData.push([
                        item.client_id,item.client,item.name,item.d_num,item.area
                    ])
                })
                exportExcel(columnTitle, columnData, "客户设备导出明细表");
            }
            }).catch(err=>{
            //err
            console.log(err.data)
            this.loading = false
            this.$message({
                        message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
            }) 
        },
        //选择每页显示数量
        choisePageSize(e){
            this.pageSize = e 
            this.search()
        },
        //选择页码
        currentChange(e){
           this.form.page = e
           this.getDevice()
        },
    }
}
</script>